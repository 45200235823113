import { useState } from "react";
import { Header } from "@/components/Header";
import { PreviewCard } from "@/components/PreviewCard";
import { Button } from "@/components/ui/button";
import { FileText } from "lucide-react";

// Temporary mock data
const MOCK_PREPRINTS = [
  {
    id: "1",
    title: "Quantum Entanglement in Neural Networks",
    authors: ["Alice Johnson", "Bob Smith"],
    abstract: "We present a novel approach to implementing quantum entanglement principles in neural network architectures...",
    category: "Quantum Computing",
    date: "2024-02-20",
    inscription_id: "6d44c8f83f834b74b1e018c5f7d8f9a2i0",
    pdf_url: "https://example.com/paper.pdf"
  },
  {
    id: "2",
    title: "Novel Approaches to Climate Modeling",
    authors: ["Carol Williams", "David Brown"],
    abstract: "This paper introduces innovative techniques for modeling climate patterns using advanced machine learning...",
    category: "Environmental Science",
    date: "2024-02-19",
    inscription_id: "8f92c7d63a924c64b2f129d6e8c7b0a1i0",
    pdf_url: "https://example.com/paper2.pdf"
  },
];

const CATEGORIES = [
  "All",
  "Quantum Computing",
  "Environmental Science",
  "Computer Science",
  "Mathematics",
  "Physics",
  "Biology"
];

const Index = () => {
  const [preprints] = useState(MOCK_PREPRINTS);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredPreprints = selectedCategory === "All" 
    ? preprints
    : preprints.filter(preprint => preprint.category === selectedCategory);

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="container mx-auto px-4 pt-24 pb-16">
        {/* Hero Section */}
        <div className="mb-16 animate-fadeIn">
          <h1 className="text-5xl font-bold mb-4">
            The Largest, Most Secure Repository for Scientific Knowledge
          </h1>
          <p className="text-xl text-muted-foreground mb-8 max-w-3xl">
            Decentralized science published on Bitcoin. A free distribution service and open-access archive for scholarly articles, ensuring permanent preservation through blockchain technology.
          </p>

          {/* How to Submit Section */}
          <div className="bg-secondary/50 rounded-lg p-8 mb-16">
            <h2 className="text-2xl font-semibold mb-4">How to Submit Your Research</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="space-y-2">
                <div className="bg-primary text-primary-foreground w-8 h-8 rounded-full flex items-center justify-center font-semibold mb-3">1</div>
                <h3 className="font-semibold">Connect Your Wallet</h3>
                <p className="text-muted-foreground">Connect your Bitcoin wallet to get started with the submission process.</p>
              </div>
              <div className="space-y-2">
                <div className="bg-primary text-primary-foreground w-8 h-8 rounded-full flex items-center justify-center font-semibold mb-3">2</div>
                <h3 className="font-semibold">Prepare Your Paper</h3>
                <p className="text-muted-foreground">Format your preprint as a PDF file and prepare the abstract and metadata.</p>
              </div>
              <div className="space-y-2">
                <div className="bg-primary text-primary-foreground w-8 h-8 rounded-full flex items-center justify-center font-semibold mb-3">3</div>
                <h3 className="font-semibold">Submit & Inscribe</h3>
                <p className="text-muted-foreground">Submit your preprint and inscribe it permanently on the Bitcoin blockchain.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Categories Section */}
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-4 animate-fadeIn">Categories</h2>
          <div className="flex flex-wrap gap-2">
            {CATEGORIES.map((category) => (
              <Button
                key={category}
                variant={selectedCategory === category ? "default" : "outline"}
                onClick={() => setSelectedCategory(category)}
                className="animate-fadeIn"
              >
                {category}
              </Button>
            ))}
          </div>
        </div>

        {/* Latest Preprints Section */}
        <h2 className="text-3xl font-bold mb-2 animate-fadeIn">Latest Preprints</h2>
        <p className="text-muted-foreground mb-8 animate-fadeIn">
          Discover the latest research papers from the scientific community
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredPreprints.map((preprint) => (
            <PreviewCard key={preprint.id} {...preprint} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default Index;