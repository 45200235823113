import { Link } from "react-router-dom";
import { Infinity, Upload } from "lucide-react";
import { Button } from "./ui/button";
import { WalletButton } from "./WalletButton";
import { useWallet } from "@/hooks/useWallet";

export const Header = () => {
  const { address } = useWallet();

  return (
    <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-md border-b z-50">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-2 text-primary hover:opacity-80 transition-opacity">
          <Infinity className="w-8 h-8" />
          <span className="text-xl font-semibold">inkrypt</span>
        </Link>
        
        <div className="flex items-center space-x-4">
          {address && (
            <Link to="/submit">
              <Button className="flex items-center space-x-2">
                <Upload className="w-4 h-4" />
                <span>Submit Preprint</span>
              </Button>
            </Link>
          )}
          <WalletButton />
        </div>
      </div>
    </header>
  );
};