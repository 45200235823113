import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader } from "./ui/card";
import { Badge } from "./ui/badge";
import { ExternalLink } from "lucide-react";

interface PreviewCardProps {
  id: string;
  title: string;
  authors: string[];
  abstract: string;
  category: string;
  date: string;
  inscription_id?: string;
  pdf_url?: string;
}

export const PreviewCard = ({
  id,
  title,
  authors,
  abstract,
  category,
  date,
  inscription_id,
  pdf_url,
}: PreviewCardProps) => {
  return (
    <Link to={`/preprint/${id}`}>
      <Card className="h-full hover:shadow-lg transition-shadow duration-200 animate-fadeIn bg-background/50 backdrop-blur-sm border-accent">
        <CardHeader>
          <div className="flex items-start justify-between gap-4">
            <Badge variant="outline" className="mb-2 shrink-0">
              {category}
            </Badge>
            <time className="text-sm text-muted-foreground whitespace-nowrap">{date}</time>
          </div>
          <h3 className="text-xl font-semibold leading-tight hover:text-primary/80 transition-colors">
            {title}
          </h3>
          <p className="text-sm text-muted-foreground">
            {authors.join(", ")}
          </p>
        </CardHeader>
        <CardContent className="space-y-4">
          <p className="text-sm text-muted-foreground line-clamp-3">
            {abstract}
          </p>
          
          {inscription_id && (
            <div className="pt-4 border-t border-accent">
              <div className="flex items-center justify-between text-xs text-muted-foreground">
                <span className="font-mono">Inscription ID: {inscription_id.slice(0, 8)}...</span>
                {pdf_url && (
                  <a 
                    href={pdf_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 hover:text-primary transition-colors"
                    onClick={(e) => e.stopPropagation()}
                  >
                    View PDF <ExternalLink className="h-3 w-3" />
                  </a>
                )}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </Link>
  );
};