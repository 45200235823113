import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "@/components/Header";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

const CATEGORIES = [
  "Physics",
  "Mathematics",
  "Computer Science",
  "Biology",
  "Chemistry",
  "Environmental Science",
  "Economics",
  "Social Sciences",
];

const Submit = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // TODO: Implement actual submission logic with wallet signing and inscription
      console.log("Submitting preprint...");
      await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate API call
      navigate("/");
    } catch (error) {
      console.error("Submission failed:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="container mx-auto px-4 pt-24 pb-16">
        <Card className="max-w-4xl mx-auto animate-fadeIn">
          <CardHeader>
            <CardTitle className="text-3xl">Submit Preprint</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <label className="text-sm font-medium">Title</label>
                <Input required placeholder="Enter the title of your paper" />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Authors</label>
                <Input required placeholder="Enter authors (comma separated)" />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Category</label>
                <Select required>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    {CATEGORIES.map((category) => (
                      <SelectItem key={category} value={category.toLowerCase()}>
                        {category}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Abstract</label>
                <Textarea required placeholder="Enter your abstract" className="h-32" />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">PDF File</label>
                <Input
                  type="file"
                  accept=".pdf"
                  required
                  onChange={(e) => setSelectedFile(e.target.files?.[0] || null)}
                />
              </div>

              <Button
                type="submit"
                className="w-full"
                disabled={isSubmitting || !selectedFile}
              >
                {isSubmitting ? "Submitting..." : "Submit Preprint"}
              </Button>
            </form>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default Submit;