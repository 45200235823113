import { Button } from "./ui/button";
import { ChevronDown, LogOut } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useWallet } from "@/hooks/useWallet";

export const WalletButton = () => {
  const { address, isConnecting, handleConnect, handleDisconnect } = useWallet();

  if (address) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" className="flex items-center space-x-2 bg-white border shadow-sm">
            <span className="text-sm">{`${address.slice(0, 6)}...${address.slice(-4)}`}</span>
            <ChevronDown className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56 bg-white">
          <DropdownMenuItem className="text-sm text-muted-foreground">
            Connected: {`${address.slice(0, 6)}...${address.slice(-4)}`}
          </DropdownMenuItem>
          <DropdownMenuItem 
            onClick={handleDisconnect}
            className="text-red-600 cursor-pointer flex items-center"
          >
            <LogOut className="mr-2 h-4 w-4" />
            Disconnect
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <Button 
      variant="secondary"
      onClick={handleConnect} 
      className="text-sm bg-white border shadow-sm"
      disabled={isConnecting}
    >
      {isConnecting ? "Connecting..." : "Connect Wallet"}
    </Button>
  );
};