import { supabase } from "@/integrations/supabase/client";

export const createWalletSession = async (ordinalsAddress: string) => {
  console.log("[Wallet Session] Creating new session for address:", ordinalsAddress);
  
  const session = {
    address: ordinalsAddress,
    timestamp: new Date().toISOString(),
    lastActive: new Date().toISOString()
  };

  console.log("[Wallet Session] Setting session data:", session);
  localStorage.setItem("walletSession", JSON.stringify(session));

  try {
    console.log("[Wallet Session] Upserting profile in Supabase");
    const { error: profileError } = await supabase
      .from('profiles')
      .upsert({
        wallet_address: ordinalsAddress,
        wallet_type: 'software',
        last_signed_in: new Date().toISOString()
      }, {
        onConflict: 'wallet_address'
      });

    if (profileError) {
      console.error("[Wallet Session] Error saving profile:", profileError);
      throw profileError;
    }
    
    console.log("[Wallet Session] Successfully created session and profile");
  } catch (error) {
    console.error("[Wallet Session] Error in createWalletSession:", error);
    throw error;
  }
};

export const checkWalletSession = () => {
  console.log("[Wallet Session] Checking for existing session");
  const sessionData = localStorage.getItem("walletSession");
  
  if (sessionData) {
    try {
      const session = JSON.parse(sessionData);
      console.log("[Wallet Session] Found existing session:", session);
      return session.address;
    } catch (error) {
      console.error("[Wallet Session] Error parsing session data:", error);
      localStorage.clear();
      return null;
    }
  }
  
  console.log("[Wallet Session] No existing session found");
  return null;
};