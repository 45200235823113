import { toast } from "sonner";

type AccountChangeEvent = {
  type: "accountChange";
  result?: {
    addresses: Array<{
      address: string;
      publicKey: string;
      purpose: string;
      addressType: string;
      network: string;
    }>;
  };
};

export const setupWalletListeners = (
  address: string | null,
  setAddress: (address: string | null) => void,
  handleDisconnect: () => void
) => {
  console.log("[Wallet Events] Starting to set up wallet event listeners...");
  console.log("[Wallet Events] Current window.btc state:", window.btc);
  console.log("[Wallet Events] Current connected address:", address);
  
  try {
    if (!window.btc) {
      console.warn("[Wallet Events] No btc object found in window! Event listeners cannot be set up");
      return () => {};
    }

    // Account change listener with detailed logging
    console.log("[Wallet Events] Setting up account change listener...");
    const removeAccountChangeListener = window.btc?.addListener('accountChange', (event: AccountChangeEvent) => {
      console.log('[Wallet Events] Account changed event received:', event);
      console.log('[Wallet Events] Previous address:', address);
      console.log('[Wallet Events] Full event data:', JSON.stringify(event, null, 2));
      
      if (event?.result?.addresses?.length > 0) {
        const ordinalsAddress = event.result.addresses.find(addr => addr.purpose === 'ordinals');
        console.log('[Wallet Events] Found ordinals address in event:', ordinalsAddress);
        
        if (ordinalsAddress) {
          console.log('[Wallet Events] Setting new ordinals address:', ordinalsAddress.address);
          console.log('[Wallet Events] Previous address for comparison:', address);
          
          if (address !== ordinalsAddress.address) {
            console.log('[Wallet Events] Address has changed, updating state and session');
            setAddress(ordinalsAddress.address);
            
            // Update session
            const session = {
              address: ordinalsAddress.address,
              timestamp: new Date().toISOString(),
              lastActive: new Date().toISOString()
            };
            console.log('[Wallet Events] Updating session with new data:', session);
            localStorage.setItem("walletSession", JSON.stringify(session));
            
            toast.success(`Wallet switched to ${ordinalsAddress.address.slice(0, 6)}...${ordinalsAddress.address.slice(-4)}`);
          } else {
            console.log('[Wallet Events] Address unchanged, no update needed');
          }
        } else {
          console.warn('[Wallet Events] No ordinals address found in account change event');
        }
      } else {
        console.warn('[Wallet Events] No addresses found in account change event');
        handleDisconnect();
      }
    });

    // Disconnect listener with detailed logging
    console.log("[Wallet Events] Setting up disconnect listener...");
    const removeDisconnectListener = window.btc?.addListener('disconnect', () => {
      console.log('[Wallet Events] Disconnect event received');
      console.log('[Wallet Events] Current address before disconnect:', address);
      handleDisconnect();
    });

    // Network change listener with detailed logging
    console.log("[Wallet Events] Setting up network change listener...");
    const removeNetworkChangeListener = window.btc?.addListener('networkChange', (event) => {
      console.log('[Wallet Events] Network change event received:', event);
      console.log('[Wallet Events] Current address before network change:', address);
      handleDisconnect();
    });

    console.log('[Wallet Events] Successfully set up all event listeners');
    console.log('[Wallet Events] Active listeners:', {
      accountChange: removeAccountChangeListener,
      disconnect: removeDisconnectListener,
      networkChange: removeNetworkChangeListener
    });
    
    return () => {
      console.log("[Wallet Events] Cleaning up wallet event listeners...");
      console.log("[Wallet Events] Current listeners being removed:", {
        accountChange: removeAccountChangeListener,
        disconnect: removeDisconnectListener,
        networkChange: removeNetworkChangeListener
      });
      removeAccountChangeListener?.();
      removeDisconnectListener?.();
      removeNetworkChangeListener?.();
      console.log("[Wallet Events] All listeners removed");
    };
  } catch (error) {
    console.error("[Wallet Events] Error setting up wallet listeners:", error);
    console.error("[Wallet Events] Full error details:", {
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });
    return () => {};
  }
};