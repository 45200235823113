import { useState, useEffect, useCallback } from "react";
import { request, AddressPurpose, getProviders } from "sats-connect";
import { loadSelector, selectWalletProvider } from "@sats-connect/ui";
import { toast } from "sonner";
import { setupWalletListeners } from "./useWalletEvents";
import { createWalletSession, checkWalletSession } from "./useWalletSession";

export const useWallet = () => {
  const [address, setAddress] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const handleDisconnect = useCallback(() => {
    console.log("[Wallet] Disconnecting wallet...");
    console.log("[Wallet] Current address before disconnect:", address);
    
    localStorage.clear();
    setAddress(null);
    toast.success("Wallet disconnected");
    
    console.log("[Wallet] State after disconnect - Address:", null);
    console.log("[Wallet] Local storage cleared");
    
    window.location.reload();
  }, [address]);

  const handleConnect = useCallback(async () => {
    if (isConnecting) {
      console.log("[Wallet] Already connecting, skipping...");
      return;
    }
    
    try {
      setIsConnecting(true);
      console.log("[Wallet] Initiating wallet connection...");
      
      const providers = getProviders();
      console.log("[Wallet] Available providers:", providers);

      if (!providers || providers.length === 0) {
        throw new Error("No wallet providers found. Please install Xverse Wallet and refresh the page.");
      }

      // Load and show wallet selector UI
      loadSelector();
      const selectedProvider = await selectWalletProvider({
        options: [{
          name: "Xverse",
          icon: "/lovable-uploads/6b0afbbf-55fb-4620-acf9-47084f6181b2.png",
          id: "xverse"
        }]
      });

      if (!selectedProvider) {
        throw new Error("No wallet selected");
      }

      // Close the selector UI immediately after selection
      const selectorElement = document.querySelector('[role="dialog"]');
      if (selectorElement) {
        selectorElement.remove();
      }

      console.log("[Wallet] Clearing existing wallet session...");
      localStorage.clear();
      
      // Step 1: Connect wallet
      console.log("[Wallet] Step 1: Requesting initial wallet connection...");
      const connectResponse = await request("wallet_connect", {
        addresses: [AddressPurpose.Ordinals, AddressPurpose.Payment],
        message: "Connect your wallet to submit preprints"
      });
      
      console.log("[Wallet] Initial wallet connection response:", connectResponse);

      if (connectResponse.status === 'success' && connectResponse.result?.addresses) {
        const ordinalsAddress = connectResponse.result.addresses.find(
          addr => addr.purpose === AddressPurpose.Ordinals
        );

        if (!ordinalsAddress) {
          console.error("[Wallet] No ordinals address found in response");
          throw new Error("No ordinals address found in wallet response");
        }

        console.log("[Wallet] Creating wallet session for address:", ordinalsAddress.address);
        await createWalletSession(ordinalsAddress.address);
        setAddress(ordinalsAddress.address);
        
        toast.success("Wallet connected successfully");
        console.log("[Wallet] Connected:", ordinalsAddress.address);
      } else {
        console.error("[Wallet] Invalid connection response format:", connectResponse);
        throw new Error("Failed to connect wallet: Invalid response format");
      }
    } catch (error) {
      console.error("[Wallet] Failed to connect wallet:", error);
      toast.error(error instanceof Error ? error.message : "Failed to connect wallet. Please try again.");
    } finally {
      setIsConnecting(false);
    }
  }, [isConnecting]);

  useEffect(() => {
    console.log("[Wallet] Initial mount, checking session and setting up listeners");
    const sessionAddress = checkWalletSession();
    
    if (sessionAddress) {
      console.log("[Wallet] Found existing session, setting up listeners for:", sessionAddress);
      setAddress(sessionAddress);
      
      // Setup listeners and store cleanup function
      const cleanup = setupWalletListeners(sessionAddress, setAddress, handleDisconnect);
      
      // Return cleanup function to be called on unmount
      return () => {
        console.log("[Wallet] Component unmounting, cleaning up listeners");
        cleanup();
      };
    }
  }, [handleDisconnect]);

  // Set up listeners after successful connection
  useEffect(() => {
    if (address) {
      console.log("[Wallet] Address changed, setting up new listeners for:", address);
      const cleanup = setupWalletListeners(address, setAddress, handleDisconnect);
      return () => {
        console.log("[Wallet] Address changed/component unmounting, cleaning up old listeners");
        cleanup();
      };
    }
  }, [address, handleDisconnect]);

  return {
    address,
    isConnecting,
    handleConnect,
    handleDisconnect
  };
};
